import React from "react";
import { Link } from "react-router-dom";
import Client1 from '../assets/images/client/01.jpg'
import Client2 from '../assets/images/client/02.jpg'
import Client3 from '../assets/images/client/03.jpg'
import Client4 from '../assets/images/client/04.jpg'
import Client5 from '../assets/images/client/05.jpg'
import Client6 from '../assets/images/client/06.jpg'
import Client7 from '../assets/images/client/07.jpg'
import Client8 from '../assets/images/client/08.jpg'

export default function ClientsTwo(){
    return(
        <>
         <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">La opinion de nuestros usuarios</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Los testimonios de nuestros usuarios reflejan el impacto de nuestra plataforma. Descubre su satisfacción y éxito en sus propias palabras.</p>
                </div>
            </div>

            <div className="container-fluid relative overflow-hidden">
                <div className="grid grid-cols-1 mt-6">
                    <div className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                        <div className="slide-track flex items-center">
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Juanita Gómez</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">La mejor parte de Audilex es que logro que mi eficiencia mejorará notablemente. Ya no transcribo a mano por lo que puedo dedicar más tiempo a mis casos y tiempo libre. </p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Andés Suarez</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">La precisión de Audilex es impresionante. Además, su interfaz es super intuitiva y fácil de usar.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">María Núñez</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Transformar videos o audios a texto logró liberar mucho espacio tanto en mi computador como en la nube, ya no tengo la necesidad temprana de aumentar mi almacenamiento.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Luz Caraballo</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Realmente valoro el tiempo que me ahorra. Recomendado!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Diego Torres</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">La adaptabilidad de Audilex a mis necesidades es excepcional, y soporte siempre está atento cuando lo necesito.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Lina Morales</Link>
                                                <p className="text-slate-400">Abogada</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Desde que uso Audilex, he notado una diferencia enorme. Las transcripciones son rápidas y su precisión es vital para mis documentos legales.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Ricardo Álvarez</Link>
                                                <p className="text-slate-400">Socio gerente</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Ha revolucionado cómo manejo las transcripciones en mi firma. Es eficiente y la seguridad de los datos me da total confianza.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Camila Sanchez</Link>
                                                <p className="text-slate-400">Asistente legal</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Lo que más valoro de Audilex es su facilidad de uso y cómo respeta la privacidad de mis clientes. Ha simplificado enormemente mi carga de trabajo.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Paola Jaramillo</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Una herramienta que definitivamente les cambiará la vida</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Julian Luna</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">El equipo de soporte es increíblemente amable y servicial. </p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Maria Pardo</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">No sabia cuanto necesitaba una herramienta así, antes utilizaba servicios manuales que tardaban mucho tiempo y eran poco confiables</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Benito Londoñez</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">¡Super recomendada!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Rebeca Montero</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Su profesionalismo y dedicación son insuperables. Desde el momento en que los conocí, me sorprendió su capacidad para entender mis necesidades y proporcionar soluciones efectivas.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Valentina Ramirez</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Excelente forma de incorporar tecnología eficiente a mis procesos juridicos.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Andrea Cruz</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">¡Estoy encantado de recomendar Audilex! He utilizado su servicio durante varios meses y estoy extremadamente satisfecho con la calidad de las transcripciones</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Esteban Bolivar</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Una herramienta espectacular</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Javier Herrera</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Es muy fácil de usar. Estoy muy agradecido por esta aplicación y la recomendaría.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Juan Mendonza</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Desde el inicio, su equipo de expertos mostró una gran disposición y profesionalismo para ayudarme con mis necesidades emoresariales</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Ingrid Ortiz</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">¡Excepcional!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Barbara Hoyos</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Estoy enormemente agradecido por haber encontrado a Audilex y recomendaría sus servicios a cualquiera que busque mejorar su efectividad</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Jose Martinez</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">El servicio al cliente es excepcional. Siempre están dispuestos a responder mis preguntas y resolver cualquier problema que pueda surgir. </p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Angel Carvajal</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Estoy encantado de recomendar Audilex como un servicio de alta calidad y confianza. </p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client7}className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Andrés Moreno</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Estoy realmente impresionado con Audilex. Como alguien con problemas de audición, siempre he tenido dificultades para escuchar y entender claramente las conversaciones al momento de transcribir.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Monica Quiroz</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Estoy muy contento de haber descubierto Audilex. Esta plataforma ha sido de gran ayuda. </p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Miguel Peña</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Definitivamente seguire usando esta herramienta, el tiempo que ahorra lo vale.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Tobias Galeano</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">La mejor herramienta de transcripciones que he probado en Colombia.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client3}className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carlos Reyes</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Al fin alguien que resuelve este problema eficientemente.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Alejandro Cortez</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Me alegra que como abogados hayan empresas que busquen encargarse de la eficiencia de nuestros procesos con tecnología. ¡Excelente!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Ismael Méndez</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">¡Muy buena ayuda!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Paul Rojas</Link>
                                                <p className="text-slate-400">Usuario</p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Una herramienta super sencilla y facil de utilizar, literal puedo tener una transcripcion en cuestion de minutos</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Laura Díaz</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Me parece super economica en comparación a todo el tiempo que he logrado ahorrar en trasliteraciones</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carolina Espinosa</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Tuve unos problemas al inicio con los creditos, sin embargo el equipo de soporte pudo resolverlos rápida y facilmente. Muy atentos, gracias.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Ricardo Albelaño</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Al principio me encontraba eceptico de la presicion pero la realidad es que es super exacta y o tengo necesidad de corregir errores</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">David Bolaños</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Execelente!</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="slide h-auto md:w-[360px] w-72 m-2">
                                <ul className="space-y-4">
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Angelica Suarez</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            <p className="text-slate-400">Esta herramienta ha liberdo mucho espacio dentro de mi tiempo laboral y personal</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                    </li>
            
                                    <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                                        <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                                            <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt=""/>
            
                                            <div className="ps-4">
                                                <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Juan Carlos Guzmán</Link>
                                                <p className="text-slate-400"></p>
                                            </div>
                                        </div>
            
                                        <div className="mt-6">
                                            {/* <p className="text-slate-400">This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important.</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul> */}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}