import React from "react";
import { Link } from "react-router-dom";

export default function AmazingFeatures(){
    const featureData = [
        {
            icon:"mdi mdi-flip-horizontal",
            title:'Reconocimiento de hablantes',
            desc:' Audilex distingue entre las diferentes voces, asignando cada intervención a su hablante correspondiente.'
        },
        {
            icon:"mdi mdi-email-edit-outline",
            title:'Marcación de tiempo precisa',
            desc:'Proporcionamos marcas de tiempo por intervención para mejorar la navegabilidad y le referenciación de la información.'
        },
        {
            icon:"mdi mdi-star-outline",
            title:'Soporte 24/7',
            desc:' Brindamos soporte continuo para asegurar una experiencia de usuario positiva y sin interrupciones.'
        },
        {
            icon:"mdi mdi-bookmark-outline",
            title:'Recorte de silencios',
            desc:'Gracias a nuestra tecnología de detección de silencios, conseguimos eliminar estos intervalos para optimizar tus costos de transcripción y obtener un resultado más conciso y exacto.'
        },
        {
            icon:"mdi mdi-account-check-outline",
            title:'Actualizaciones constantes',
            desc:'Continuamos mejorando nuestra plataforma mediante actualizaciones regulares que enriquecen su funcionalidad y precisión, satisfaciendo así tus necesidades individuales.'
        },
        {
            icon:"mdi mdi-comment-outline",
            title:'Multidispositivo',
            desc:'Facilitamos el acceso y uso de nuestra plataforma desde cualquier dispositivo, simplificando así su gestión.'
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Nuestras funciones</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Descubre todas las funciones que nuestro software<br />ofrece para cubrir tus necesidades.</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item, index) =>{
                        return(
                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg bg-white dark:bg-slate-900" key={index}>
                                <i className={`${item.icon} text-4xl bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text`}></i>
        
                                <div className="content mt-7">
                                    <Link to="" className="title h5 text-lg font-medium hover:text-amber-400 duration-500">{item.title}</Link>
                                    <p className="text-slate-400 mt-3">{item.desc}</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="" className="hover:text-amber-400 font-medium duration-500">Más información <i className="mdi mdi-arrow-right align-middle"></i></Link>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}