/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://3nacam4eyzctzjspf2qwa4iu6q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-mbv577lirjcebdxatyho2xpr3e",
    "aws_cognito_identity_pool_id": "us-east-1:69449263-ecab-4382-88cb-953eec3c126d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KVH2V5nRl",
    "aws_user_pools_web_client_id": "52npmh2cb6hsq1cmrv2vgtbe59",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "trs-834234235211728-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
