import React, { useState } from "react";
import { Link } from "react-router-dom";

import {MdKeyboardArrowDown} from '../assets/icons/vander'

export default function Faq(){
    const [activeIndex, setActiveIndex] = useState(1)
    const accordionData = [
        {
            id:1,
            title:'¿Qué tipo de archivos son compatibles para la transcripción?',
            desc:'Audilex es compatible con una amplia gama de formatos de audio y video para la transcripción, facilitando su uso en diversas aplicaciones legales.'
        },
        {
            id:2,
            title:'¿Cómo puedo acceder a las transcripciones completadas?',
            desc:'Una vez completada, puedes acceder a tus transcripciones de manera segura a través de nuestro portal en línea, donde estarán disponibles para descargar.'
        },
        {
            id:3,
            title:'¿Cuál es el nivel de precisión que se puede esperar de las transcripciones?',
            desc:'Nos enorgullecemos poder decir que tenemos uno de los modelos más precisos del mercado español y continuamos superando los estándares del sector para entregarte resultados confiables. Hoy en día puedes esperar un 97% de precisión.'
        },
        {
            id:4,
            title:'¿Qué cantidad de tiempo se requiere para completar una transcripción?',
            desc:'El tiempo para completar una transcripción varía según la longitud y la claridad del audio. Sin embargo, nos esforzamos por entregarlas de manera oportuna sin comprometer la calidad.'
        },
        {
            id: 5,
            title: 'Si tengo un archivo en mi tablet o teléfono, ¿Es posible subirlo directamente desde el dispositivo?',
            desc: '¡Por supuesto! Puedes subir archivos directamente desde tu tablet o teléfono a nuestra plataforma, estos se procesarán con normalidad.'
        },
        {
            id: 6,
            title: '¿Cómo es la presentación final de sus transcripciones?',
            desc: 'La presentación final de nuestras transcripciones es en formato Word. Diseñado bajo una estructura que facilita la lectura y la referencia futura (ver ejemplo aquí) . En el caso de necesitarlo en un formato de empresa o gubernamental favor de contactarnos.'
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
                    <div className="lg:col-span-4 md:mb-0 mb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">¿Tienes otra pregunta?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto mb-6">Ofrecemos tiempos de respuesta ágiles, garantizando un máximo de 24 horas.</p>

                        <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-amber-400 border-gray-100 dark:border-gray-800 hover:border-amber-400 dark:hover:border-amber-400 text-slate-900 dark:text-white hover:text-white rounded-md">Contáctanos</Link>
                    </div>

                    <div className="lg:col-span-8 md:mt-0 mt-8">
                        {accordionData.map((item,index)=>{
                            return(
                                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button type="button" onClick={()=>setActiveIndex(item.id)} className={`${activeIndex === item.id ? "bg-gray-50 dark:bg-slate-800 text-amber-400" : ""} flex justify-between items-center p-5 w-full font-medium text-start`}>
                                            <span>{item.title}</span>
                                            <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`}/>
                                        </button>
                                    </h2>
                                    <div className={activeIndex === item.id ? "" : "hidden"}>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div> 
        </>
    )
}