import React,{ useState} from "react";
import { Link } from "react-router-dom";

import {FiCheckCircle,AiOutlineClose} from '../assets/icons/vander'

export default function Pricing(){
    const [businessPrice, setBusinessPrice] = useState(20) 
    const [professionalPrice, setProfessionalPrice] = useState(40)

    let businessUpdate = (parseFloat(businessPrice * 0.05).toFixed(1))

    let professionalUpdate = (parseFloat(professionalPrice * 0.025).toFixed(1))
    return(
        <>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Gratis</h5>
                        <p className="text-slate-400 mt-2">Para quienes gusten probar Audilex y convencerse.</p>
                        <div className="flex mt-4">
                            <span className="text-lg font-semibold">COP</span>
                            <span className="text-5xl font-semibold mb-0 ms-1">0</span>
                        </div>
                        <p className="text-slate-400 uppercase text-xs">15 mins o 1/6 del archivo</p>

                        <div className="mt-6">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400/5 hover:bg-amber-400 rounded border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white">Pruébalo gratis</Link>

                            <p className="text-slate-400 text-sm mt-3">No requiere método de pago.</p>
                        </div>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">¿Qué incluye?</li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /><span className="text-slate-900 dark:text-white me-1 font-semibold">1/6 o 15 mins gratis</span>cada dos días</li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Estampas de tiempo precisas</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Reconocimiento de hablantes</span></li>
                            <li className="flex items-center mt-2 text-slate-400"><AiOutlineClose className="h-[18px] w-[18px] me-2"/> Soporte</li>
                            <li className="flex items-center mt-2 text-slate-400"><AiOutlineClose className="h-[18px] w-[18px] me-2" /> Disponible 24/7</li>
                        </ul>
                    </div>
                </div>

                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Abogados</h5>
                        <p className="text-slate-400 mt-2">Para los abogados que necesiten transcripciones periódicas.</p>
                        
                        <div className="relative">
                            <div className="flex mt-4">
                                <span className="text-lg font-semibold">COP</span>
                                <span className="">
                                    <input type="hidden" id="business-amount" className="form-control"/>
                                    <p className="text-5xl font-semibold mb-0 ms-1" id="busi-amt">{businessPrice}</p>
                                    <p className="text-slate-400 uppercase text-xs">por minuto</p>
                                </span>
                            </div>

                            <div className="relative mt-4">
                                <label htmlFor="business-price" className="form-label"></label>
                                <input id="business-price" type="range" defaultValue="20" min={20} max={200} onChange={(e) => setBusinessPrice(e.target.value)} className="w-full h-1 bg-gray-50 dark:bg-slate-800 rounded-lg appearance-none cursor-pointer"/>
                                <span className="font-semibold text-lg absolute end-0 -top-5">
                                    <input type="hidden" id="business-update" className="form-control"/>
                                    <span className=""></span>
                                    <p className="inline-block" id="busi-update">{businessUpdate}</p>
                                    <span>X</span>
                                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Empieza ya</Link>
                        </div>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">¿Qué incluye?:</li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Paquete</span> bajo tus necesidades</li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Disponible 24/7</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Soporte Básico</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white mx-1 font-semibold">Reconocimiento de hablantes</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Estampas de tiempo precisas</span></li>
                        </ul>
                    </div>
                </div>

                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Empresas</h5>
                        <p className="text-slate-400 mt-2">Para empresas o intituciones que requieran altos volumenes o soluciones a la medida.</p>

                        <div className="relative">
                            <div className="flex mt-4">
                                <span className="text-lg font-semibold"></span>
                                <span className="">
                                    <input type="hidden" id="professional-amount" className="form-control"/>
                                    <p className="text-5xl font-semibold mb-0 ms-1" id="pro-amt">{professionalPrice}</p>
                                    <p className="text-slate-400 uppercase text-xs"></p>
                                </span>
                            </div>

                            <div className="relative mt-4">
                                <label htmlFor="professional-price" className="form-label"></label>
                                <input id="professional-price" type="range" defaultValue="40" min={40} max={400} onChange={(e) => setProfessionalPrice(e.target.value)} className="w-full h-1 bg-gray-50 dark:bg-slate-800 rounded-lg appearance-none cursor-pointer"/>
                                <span className="font-semibold text-lg absolute end-0 -top-5">
                                    <input type="hidden" id="professional-update" className="form-control"/>
                                    <span className=""></span>
                                    <p className="inline-block" id="pro-update">{professionalUpdate}</p>
                                    <span>X</span>
                                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Contáctanos</Link>
                        </div>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase"></li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">¿Requieres altos volúmenes de transcripciones?</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">¿Digitalización de contenido?</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">¿Categorización de videos/audios?</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white mx-1 font-semibold">¿Optimización del espacio?</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}